<gp-angular-loader *ngIf="loading" class="form-loader blur-below"></gp-angular-loader>

<mat-toolbar class="hide-on-small-and-down">
    <h1>{{ 'Provider.Contact.Title' | translate }}</h1>
</mat-toolbar>

<div class="hide-on-small-and-down padding-bottom-M"></div>

<mat-toolbar>
    <h2 class="hide-on-small-and-down">{{ 'Provider.Contact.Subtitle.Large' | translate }}</h2>
    <h1 class="hide-on-med-and-up">{{ 'Provider.Contact.Subtitle.Small' | translate }}</h1>
</mat-toolbar>

<form [formGroup]="form" #contactForm="ngForm" (keydown.enter)="$event.preventDefault()" class="padding-horizontal-S">
    <div class="row">
        <mat-form-field appearance="outline" class="col s12 m12">
            <mat-label>{{ 'Pages.Provider.Contact.Category' | translate }}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="category">
                <mat-option value="{{ ContactCategoryEnumDTO.ORDER }}">{{ 'Pages.Provider.Contact.Category.Order' | translate }}</mat-option>
                <mat-option value="{{ ContactCategoryEnumDTO.INFO }}">{{ 'Pages.Provider.Contact.Category.Info' | translate }}</mat-option>
            </mat-select>
            <mat-error>{{messageService.error(form.controls.category, 'Pages.Provider.Contact.Category' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col s12 m12">
            <mat-label>{{ 'Pages.Provider.Contact.Subject' | translate }}</mat-label>
            <input matInput formControlName="subject">
            <mat-error>{{messageService.error(form.controls.subject, 'Pages.Provider.Contact.Subject' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col s12 m12">
            <mat-label>{{ 'Pages.Provider.Contact.Description' | translate }}</mat-label>
            <textarea matInput formControlName="message" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="4"></textarea>
            <mat-error>{{messageService.error(form.controls.message, 'Pages.Provider.Contact.Description' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="clearfix col s12 m6" floatLabel="always">
            <mat-label>{{'App.General.Salutation' | translate}}</mat-label>
            <mat-select hideSingleSelectionIndicator="true" formControlName="gender" placeholder="{{'App.General.Select.Option.None' | translate}}">
                <mat-option *ngFor="let item of (listKeySalutation$ | async)" [value]="item.text">
                    {{item.text}}
                </mat-option>
            </mat-select>
            <mat-error>{{messageService.error(form.controls.gender, 'App.General.Salutation' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="clearfix col s12 m6">
            <mat-label>{{'App.General.Lastname' | translate}}</mat-label>
            <input matInput formControlName="lastName">
            <mat-error>{{messageService.error(form.controls.lastName, 'App.General.Lastname' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col s12 m6">
            <mat-label>{{'App.General.Firstname' | translate}}</mat-label>
            <input matInput formControlName="firstName">
            <mat-error>{{messageService.error(form.controls.firstName, 'App.General.Firstname' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col s12 m6">
            <mat-label>{{'App.General.Email' | translate}}</mat-label>
            <input matInput formControlName="email">
            <mat-error>{{messageService.error(form.controls.email, 'App.General.Email' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col s12 m6">
            <mat-label>{{'App.General.Phone' | translate}}</mat-label>
            <input matInput formControlName="telephone" type="tel" name="telephone" [autocomplete]="'on'" appHealPhoneFormatter>
            <mat-error>{{messageService.error(form.controls.telephone, 'App.General.Phone' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col s12 m6">
            <mat-label>{{'App.General.Practice_Name' | translate}}</mat-label>
            <input matInput formControlName="practiceName">
            <mat-error>{{messageService.error(form.controls.practiceName, 'App.General.Practice_Name' | translate)}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col s12 m6" [ngClass]="ncnClass">
            <mat-label>{{'App.General.NOVENTI_Custom_Number' | translate}}</mat-label>
            <input matInput formControlName="noventiCustomerNumber" [readonly]="!!ncnClass">
            <mat-error>{{messageService.error(form.controls.noventiCustomerNumber, 'App.General.NOVENTI_Custom_Number' | translate)}}</mat-error>
        </mat-form-field>

        <div class="col s6 offset-s6 m3 offset-m9">
            <button mat-raised-button color="primary" class="block"
                    [ngClass]="{'disabled' : form.invalid || form.pristine}"
                    (click)="(form.valid && form.dirty) ? send() : sendDisabled()">
                {{ 'App.General.Send' | translate }}
            </button>
        </div>
    </div>
</form>
