import { Injectable } from '@angular/core';

@Injectable()
export class HashIdService {

	public salt = '';
	public minLength = 0;
	public alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
	public seps = 'cfhistuCFHISTU';
	public guards;

	private _escapeRegExp(s: string) {
		return s.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
	}

	private _parseInt(v, radix) {
		return /^(-|\+)?([0-9]+|Infinity)$/.test(v) ? parseInt(v, radix) : NaN;
	}

	constructor() {
		const minAlphabetLength = 16;
		const sepDiv = 3.5;
		const guardDiv = 12;
		const errorAlphabetLength = 'error: alphabet must contain at least X unique characters';
		const errorAlphabetSpace = 'error: alphabet cannot contain spaces';
		let uniqueAlphabet = '';
		let sepsLength: number;
		let diff: number;

		this.minLength = this._parseInt(this.minLength, 10) > 0 ? this.minLength : 0;
		this.salt = typeof this.salt === 'string' ? this.salt : '';

		// if (typeof this.alphabet === 'string') {
		// 	this.alphabet = this.alphabet;
		// }

		for (let i = 0; i !== this.alphabet.length; i++) {
			if (uniqueAlphabet.indexOf(this.alphabet.charAt(i)) === -1) {
				uniqueAlphabet += this.alphabet.charAt(i);
			}
		}

		this.alphabet = uniqueAlphabet;

		if (this.alphabet.length < minAlphabetLength) {
			throw errorAlphabetLength.replace('X', '' + minAlphabetLength);
		}

		if (this.alphabet.search(' ') !== -1) {
			throw errorAlphabetSpace;
		}
		/*
            `this.seps` should contain only characters present in `this.alphabet`
            `this.alphabet` should not contains `this.seps`
        */


		for (let _i = 0; _i !== this.seps.length; _i++) {
			const j = this.alphabet.indexOf(this.seps.charAt(_i));

			if (j === -1) {
				this.seps = this.seps.substr(0, _i) + ' ' + this.seps.substr(_i + 1);
			} else {
				this.alphabet = this.alphabet.substr(0, j) + ' ' + this.alphabet.substr(j + 1);
			}
		}

		this.alphabet = this.alphabet.replace(/ /g, '');
		this.seps = this.seps.replace(/ /g, '');
		this.seps = this._shuffle(this.seps, this.salt);

		if (!this.seps.length || this.alphabet.length / this.seps.length > sepDiv) {
			sepsLength = Math.ceil(this.alphabet.length / sepDiv);

			if (sepsLength > this.seps.length) {
				diff = sepsLength - this.seps.length;
				this.seps += this.alphabet.substr(0, diff);
				this.alphabet = this.alphabet.substr(diff);
			}
		}

		this.alphabet = this._shuffle(this.alphabet, this.salt);
		const guardCount = Math.ceil(this.alphabet.length / guardDiv);

		if (this.alphabet.length < 3) {
			this.guards = this.seps.substr(0, guardCount);
			this.seps = this.seps.substr(guardCount);
		} else {
			this.guards = this.alphabet.substr(0, guardCount);
			this.alphabet = this.alphabet.substr(guardCount);
		}
	}

	public encode(numbers: string | number | string[] | number[]) {
		if (!Array.isArray(numbers)) {
			if (typeof numbers === 'number') {
				numbers = '' + numbers;
			}
			if (typeof numbers === 'string') {
				numbers = numbers.split('');
			}
		}

		for (let i = 0; i !== (numbers as Array<any>).length; i++) {
			numbers[i] = this._parseInt(numbers[i], 10);

      if ((numbers[i] as number) < 0) {
        return undefined;
      }
		}

		return this._encode(numbers) as string;
	}

	public decode(id) {
		if (!id || !id.length || typeof id !== 'string') {
			return undefined;
		}

		let result = this._decode(id, this.alphabet);
		if (Array.isArray(result)) {
			result = result.join('') as any;
		}
		return Number(result);
	}

	// public encodeHex(hex) {
	//     hex = hex.toString();
	//
	//     if (!/^[0-9a-fA-F]+$/.test(hex)) {
	//         return '';
	//     }
	//
	//     let numbers = hex.match(/[\w\W]{1,12}/g);
	//
	//     for (let i = 0; i !== numbers.length; i++) {
	//         numbers[i] = parseInt('1' + numbers[i], 16);
	//     }
	//
	//     return this.encode(numbers);
	// }
	//
	// public decodeHex(id) {
	//     let ret = [];
	//     let numbers = this.decode(id);
	//
	//     for (let i = 0; i !== numbers.length; i++) {
	//         ret += numbers[i].toString(16).substr(1);
	//     }
	//
	//     return ret;
	// }

	private _encode(numbers) {
		let ret;
		let alphabet = this.alphabet;
		let numbersIdInt = 0;

		for (let i = 0; i !== numbers.length; i++) {
			numbersIdInt += numbers[i] % (i + 100);
		}

		ret = alphabet.charAt(numbersIdInt % alphabet.length);
		const lottery = ret;

		for (let _i2 = 0; _i2 !== numbers.length; _i2++) {
			let number = numbers[_i2];
			const buffer = lottery + this.salt + alphabet;
			alphabet = this._shuffle(alphabet, buffer.substr(0, alphabet.length));

			const last = this._toAlphabet(number, alphabet);

			ret += last;

			if (_i2 + 1 < numbers.length) {
				number %= last.charCodeAt(0) + _i2;
				const sepsIndex = number % this.seps.length;
				ret += this.seps.charAt(sepsIndex);
			}
		}

		if (ret.length < this.minLength) {
			let guardIndex = (numbersIdInt + ret[0].charCodeAt(0)) % this.guards.length;
			let guard = this.guards[guardIndex];
			ret = guard + ret;

			if (ret.length < this.minLength) {
				guardIndex = (numbersIdInt + ret[2].charCodeAt(0)) % this.guards.length;
				guard = this.guards[guardIndex];
				ret += guard;
			}
		}

		const halfLength = this._parseInt(this.alphabet.length / 2, 10);

		while (ret.length < this.minLength) {
			alphabet = this._shuffle(alphabet, alphabet);
			ret = alphabet.substr(halfLength) + ret + alphabet.substr(0, halfLength);
			const excess = ret.length - this.minLength;

			if (excess > 0) {
				ret = ret.substr(excess / 2, this.minLength);
			}
		}

		return ret;
	}


	private _decode(id, alphabet) {
		let ret = [];
		let i = 0;
		let r = new RegExp('['.concat(this._escapeRegExp(this.guards), ']'), 'g');
		let idBreakdown = id.replace(r, ' ');
		let idArray = idBreakdown.split(' ');

		if (idArray.length === 3 || idArray.length === 2) {
			i = 1;
		}

		idBreakdown = idArray[i];

		if (typeof idBreakdown[0] !== 'undefined') {
			const lottery = idBreakdown[0];
			idBreakdown = idBreakdown.substr(1);
			r = new RegExp('['.concat(this._escapeRegExp(this.seps), ']'), 'g');
			idBreakdown = idBreakdown.replace(r, ' ');
			idArray = idBreakdown.split(' ');

			for (let j = 0; j !== idArray.length; j++) {
				const subId = idArray[j];
				const buffer = lottery + this.salt + alphabet;
				alphabet = this._shuffle(alphabet, buffer.substr(0, alphabet.length));
				ret.push(this._fromAlphabet(subId, alphabet));
			}

			if (this.encode(ret) !== id) {
				ret = [];
			}
		}

		return ret;
	}

	private _shuffle(alphabet, salt) {
		let integer;

		if (!salt.length) {
			return alphabet;
		}

		alphabet = alphabet.split('');

		for (let i = alphabet.length - 1, v = 0, p = 0, j = 0; i > 0; i--, v++) {
			v %= salt.length;
			p += integer = salt.charCodeAt(v);
			j = (integer + v + p) % i;
			const tmp = alphabet[j];
			alphabet[j] = alphabet[i];
			alphabet[i] = tmp;
		}

		alphabet = alphabet.join('');
		return alphabet;
	}

	private _toAlphabet(input, alphabet) {
		let id = '';

		do {
			id = alphabet.charAt(input % alphabet.length) + id;
			input = this._parseInt(input / alphabet.length, 10);
		} while (input);

		return id;
	}

	private _fromAlphabet(input, alphabet) {
		return input.split('').map((item) => {
			return alphabet.indexOf(item);
		}).reduce((carry, item) => {
			return carry * alphabet.length + item;
		}, 0);
	}

}
