import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { PAGE } from '@gp-angular/shared/schema';
import { CanDeactivateGuard, PermissionGuard } from '@gp-angular/shared/guard';
import { ContactComponent, ContentDisplayComponent, ErrorComponent, RedirectComponent } from '@gp-angular/shared/component';
import { CmsAppTypeEnumDTO } from '@noventi/gp-platform/users';

import { AppUserResolver } from './app-user.resolver';
import { IsPageCMSGuard } from './provider/_guard/is-page-cms.guard';

import { AdminAccountComponent } from './admin/account/account.component';
import { AdminProviderDetailWrapperComponent } from './admin/provider-detail-wrapper/provider-detail-wrapper.component';
import { AdminTemplateComponent } from './admin/template/template.component';

import { ProviderLegalComponent } from './provider/legal/legal.component';
import { ProviderTemplateComponent } from './provider/template/template.component';
import { RedirectProviderSyncGoogleGuard } from './redirect-provider-sync-google.guard';
import { RedirectProviderSyncOffice365Guard } from './redirect-provider-sync-office365.guard';

const routes: Routes = [
	{
		path: PAGE.ERROR.path,
		component: ErrorComponent,
		data: {title: 'Page.Error_Not_Found.Title'},
	},
	{
		path: PAGE.ADMIN.path,
		component: AdminTemplateComponent,
		data: {allowedRoles: PAGE.ADMIN.role},
		resolve: {trigger: AppUserResolver},
		canActivate: [PermissionGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: PAGE.PROVIDER.path
			},
			{
				path: PAGE.PROVIDER.path,
				children: [
					{
						path: '',
						pathMatch: 'full',
						loadChildren: () => import('@gp-angular/api/admin-provider-management').then(m => m.ApiAdminProviderManagementModule),
					},
					{
						path: PAGE.ADMIN_PROVIDER_NEW.path,
						loadChildren: () => import('@gp-angular/api/admin-provider-management').then(m => m.ApiAdminProviderManagementModule),
						data: {title: 'Pages.Provider.Access.Title'}
					},
					{
						path: `:id`,
						component: AdminProviderDetailWrapperComponent,
						loadChildren: () => import('@gp-angular/api/provider-profile').then(m => m.ApiProviderProfileModule),
						data: {title: 'Pages.Admin.Provider.Title'}
					},
					{
						path: `:id`,
						component: AdminProviderDetailWrapperComponent,
						loadChildren: () => import('@gp-angular/api/admin-provider-management').then(m => m.ApiAdminProviderManagementModule),
						data: {title: 'Pages.Provider.Access.Title'}
					},
				]
			},
			{
				path: PAGE.ADMIN_USER.path,
				loadChildren: () => import('@gp-angular/api/admin-user-management').then(m => m.ApiAdminUserManagementModule)
			},
			{
				path: PAGE.ADMIN_CMS.path,
				loadChildren: () => import('@gp-angular/api/admin-content-management').then(m => m.ApiAdminContentManagementModule)
			},
			{
				path: PAGE.ADMIN_GENERAL.path,
				loadChildren: () => import('@gp-angular/api/admin-settings').then(m => m.ApiAdminSettingsModule)
			},
			{
				path: PAGE.ADMIN_SETTING.path,
				component: AdminAccountComponent,
				loadChildren: () => import('@gp-angular/api/account').then(m => m.ApiAccountModule)
			}
		]
	},
	{
		path: PAGE.PROVIDER_SYNC_GOOGLE.path,
		component: RedirectComponent,
		canActivate: [RedirectProviderSyncGoogleGuard]
	},
	{
		path: PAGE.PROVIDER_SYNC_OFFICE365.path,
		component: RedirectComponent,
		canActivate: [RedirectProviderSyncOffice365Guard]
	},
	{
		path: PAGE.PROVIDER.path,
		component: ProviderTemplateComponent,
		data: {allowedRoles: PAGE.PROVIDER.role},
		resolve: {trigger: AppUserResolver},
		canActivate: [PermissionGuard],
		children: [
			{
				path: PAGE.PROVIDER_CALENDAR.path,
				loadChildren: () => import('@gp-angular/api/provider-appointment').then(m => m.ApiProviderAppointmentModule)
			},
			{
				path: `${PAGE.PROVIDER_MESSAGE.path}`,
				loadChildren: () => import('@gp-angular/api/provider-notification').then(m => m.ApiProviderNotificationModule)
			},
			{
				path: `${PAGE.PROVIDER_PATIENT_WAITING_LIST.path}`,
				loadChildren: () => import('@gp-angular/api/provider-patient-waiting-list').then(m => m.ApiProviderPatientWaitingListModule)
			},
			{
				path: PAGE.PROVIDER_PATIENT.path,
				loadChildren: () => import('@gp-angular/api/provider-patient-management').then(m => m.ApiProviderPatientManagementModule)
			},
			{
				path: PAGE.PROVIDER_SETTINGS.path,
				loadChildren: () => import('@gp-angular/api/provider-user-settings').then(m => m.ApiProviderUserSettingsModule)
			},
			{
				path: PAGE.PROVIDER_TREATMENT.path,
				loadChildren: () => import('@gp-angular/api/provider-treatment').then(m => m.ApiProviderTreatmentModule)
			},
			{
				path: PAGE.PROVIDER_RESOURCE.path,
				loadChildren: () => import('@gp-angular/api/provider-resource').then(m => m.ApiProviderResourceModule)
			},
			{
				path: PAGE.PROVIDER_PROFILE.path,
				loadChildren: () => import('@gp-angular/api/provider-profile').then(m => m.ApiProviderProfileModule)
			},
			{
				path: PAGE.PROVIDER_USER_MANAGEMENT.path,
				loadChildren: () => import('@gp-angular/api/provider-user-management').then(m => m.ApiProviderUserManagementModule)
			},
			{
				path: PAGE.PROVIDER_INSTITUTION.path,
				loadChildren: () => import('@gp-angular/api/provider-institution').then(m => m.ApiProviderInstitutionModule)
			},
			{
				path: PAGE.CONTACT.path,
				component: ContactComponent,
				data: {title: 'Pages.Contact.Title'},
				canDeactivate: [CanDeactivateGuard]
			},
			{
				path: PAGE.LEGAL.path,
				component: ProviderLegalComponent,
				data: {
					title: 'Pages.Provider.Legal.Title',
					allowedRoles: PAGE.PROVIDER.role
				},
				children: [
					{
						path: '',
						pathMatch: 'full',
						redirectTo: PAGE.PROVIDER_IMPRESSUM.path
					},
					{
						path: `:content`,
						component: ContentDisplayComponent,
						data: {type: CmsAppTypeEnumDTO.ADMIN},
						canActivate: []
					}
				]
			},
			{
				path: `:content`,
				component: ContentDisplayComponent,
				data: {type: CmsAppTypeEnumDTO.ADMIN},
				canActivate: [IsPageCMSGuard]
			},
			{
				path: '',
				pathMatch: 'full',
				redirectTo: PAGE.PROVIDER_PROFILE.path
			},
			{
				path: '**',
				redirectTo: PAGE.PROVIDER_PROFILE.path
			}
		]
	},
	{
		path: PAGE.GUEST.path,
		loadChildren: () => import('@gp-angular/api/guest').then(m => m.ApiGuestModule)
	},
	{
		path: '**',
		redirectTo: '/'
	}
];

@NgModule({
	imports: [
		BrowserModule,
		RouterModule.forRoot(routes, {
			useHash: false,
			// In v15, relativeLinkResolution is not configurable in the Router. It was used to opt out of an earlier bug fix that is now standard
			// relativeLinkResolution: 'legacy'
		}),
	],
	providers: [
		AppUserResolver,
		CanDeactivateGuard
	],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
