import { ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { storeLogger } from '@zerops/ngrx-store-logger';

/**
 * LOCAL_STORAGE
 */
const fullLocalStorageSyncConst = localStorageSync({
	keys: ['account', 'admin', 'application', 'appointment', 'onboarding', 'provider', 'user'], rehydrate: true, storage: localStorage
});

const localStorageSyncConst = localStorageSync({
	keys: ['application'], storage: localStorage
});

export function localStorageSyncWrapper(reducer): ActionReducer<any, any> {
	return (state, action) => {
		const newReducer = fullLocalStorageSyncConst(reducer);
		return newReducer(state, action);
	};
}

/**
 * STORE_LOGGER
 */
export const storeLoggerConst = storeLogger();

export function storeLoggerWrapper(reducer): ActionReducer<any, any> {
	return (state, action) => {
		const newReducer = storeLoggerConst(reducer);
		return newReducer(state, action);
	};
}

/**
 * META_REDUCER
 */
export const metaReducersDev: Array<MetaReducer<any, any>> = [localStorageSyncWrapper, storeLoggerWrapper];
export const metaReducersProd: Array<MetaReducer<any, any>> = [localStorageSyncWrapper];
