<div *ngIf="data">
    <mat-dialog-content>
        <h1>{{ data.content.title }}</h1>

        <ng-container [ngSwitch]="currentView" [formGroup]="form">
            <ng-container *ngSwitchCase="DialogDeleteDataViewEnum.FORM">
                <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="DialogDeleteDataViewEnum.CONFIRM">
                <ng-container *ngTemplateOutlet="confirmTemplate"></ng-container>
            </ng-container>
        </ng-container>

        <mat-slide-toggle xSmsSimulate hideIcon="true" [(ngModel)]="xSmsSimulate" *ngIf="!isProduction && form.get('emailValidation').enabled && form.get('downloadPdf').enabled">
            {{'App.General.Dev_Simulate_Sms' | translate}}
        </mat-slide-toggle>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <ng-container [ngSwitch]="currentView">
            <ng-container *ngSwitchCase="DialogDeleteDataViewEnum.FORM">
                <button mat-button color="primary" type="button" class="margin-right-M" (click)="dialogRef.close(false)">
                    {{ 'App.General.Cancel'  | translate | uppercase }}
                </button>
                <button mat-flat-button color="primary" type="button" (click)="currentView = DialogDeleteDataViewEnum.CONFIRM">
                    {{ 'App.General.More'  | translate | uppercase }}
                </button>
            </ng-container>
            <ng-container *ngSwitchCase="DialogDeleteDataViewEnum.CONFIRM">
                <button mat-button color="primary" type="button" class="margin-right-M" (click)="back()">
                    {{ 'App.General.Back'  | translate | uppercase }}
                </button>
                <button mat-flat-button color="primary" type="button" (click)="delete()" [disabled]="form.invalid">
                    {{ 'App.General.Delete'  | translate | uppercase }}
                </button>
            </ng-container>
        </ng-container>
    </mat-dialog-actions>
</div>

<ng-template #formTemplate [formGroup]="form">
    <p [innerHTML]="data.content.text"></p>

    <div *ngIf="data.content.overview" class="overview-data-wrapper padding margin-bottom">
        <p class="padding-bottom">{{ 'Modal.Delete_Data.Overview.Text' | translate }}</p>

        <div class="overview-data" *ngIf="data.content.overview.html" [innerHTML]="data.content.overview.html"></div>

        <p *ngIf="data.content.overview.hint" class="hint padding-top">{{ data.content.overview.hint }}</p>
    </div>

    <mat-slide-toggle hideIcon="true" formControlName="emailValidation" class="text-wrap" *ngIf="form.get('emailValidation').enabled">
        {{ 'Modal.Delete_Data.Email.Toggle' | translate }}
    </mat-slide-toggle>
    <mat-slide-toggle hideIcon="true" formControlName="downloadPdf" class="text-wrap" *ngIf="form.get('downloadPdf').enabled">
        {{ 'Modal.Delete_Data.Download.Toggle' | translate }}
    </mat-slide-toggle>
</ng-template>

<ng-template #confirmTemplate [formGroup]="form">
    <p class="row">
        <span *ngIf="data.content.textConfirm" [innerHTML]="data.content.textConfirm"></span>
        <span *ngIf="!data.content.textConfirm" [innerHTML]="data.content.text"></span>
    </p>

    <div *ngIf="data.confirmation" class="row">
        <p>{{translateService.instant('Modal.Delete_Data.Confirm.String.Text', {pattern: data.pattern})}}</p>
        <mat-form-field appearance="outline" class="col s12 m8 no-padding">
            <mat-label>"{{data.pattern}}" {{"App.General.Type_In" | translate}}</mat-label>
            <input matInput formControlName="confirmation">
            <mat-error>{{translateService.instant('Error.Pattern_Match_String', {pattern: data.pattern})}}</mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="this.form.get('emailValidation').value" class="row">
        <p>{{ 'Modal.Delete_Data.Confirm.Email.Text' | translate }}</p>
        <mat-form-field appearance="outline" class="col s12 m8 no-padding">
            <mat-label>{{'Modal.Delete_Data.Confirm.Email.Label' | translate}}</mat-label>
            <input matInput formControlName="email">
<!--            {{translateService.instant('Error.Pattern_Match_String', { pattern: data.pattern })}}-->
<!--            <mat-error>{{messageService.error(form.controls.email, 'App.General.Email' | translate)}}</mat-error>-->
        </mat-form-field>
    </div>
</ng-template>
