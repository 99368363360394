import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { asyncScheduler, Observable, scheduled } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import * as ApplicationActions from './+state/application.actions';
import { ApplicationFacade } from './+state/application.facade';
import { MEDIA_QUERY_SIZE, MediaQuerySizeType } from './+state/application.models';
import { EnvironmentUpdateEnum } from '@gp-angular/shared/environment';
import { AbstractComponent } from '@gp-angular/shared/abstract';
import { MediaQueryLimit } from '@gp-angular/shared/schema';
import { EnvironmentUtils } from '@gp-angular/shared/utils';
import { EnvironmentService } from '@gp-angular/service/environment';

@Injectable()
export class ApplicationService extends AbstractComponent {

	//private isInitialized = false;

	private _listener = undefined;

	constructor(
		@Inject('ENVIRONMENT') private _environment: any,
		private applicationFacade: ApplicationFacade,
		private eventManager: EventManager,
		private environmentService: EnvironmentService
	) {
		super();
	}

	//public init() {
	//	if (!this.isInitialized) {
	//		this.isInitialized = true;
	//	}
	//}

	public setMediaQuerySize(value: MediaQuerySizeType) {
		this.applicationFacade.dispatch(ApplicationActions.MediaQuerySizeChanged({mediaQuerySize: value}));
	}

	public setAppLanguage(value: string) {
		this.applicationFacade.dispatch(ApplicationActions.AppLanguage({language: value}));
	}

	public getMediaQuerySize$(): Observable<MediaQuerySizeType> {
		return this.applicationFacade.screen$;
	}

	public setVersion(version: string, updated: EnvironmentUpdateEnum) {
		this.applicationFacade.dispatch(ApplicationActions.VersionAction({
			payload: {version: version, versionUpdate: updated}
		}));
	}

	public getVersionUpdateRequired$(): Observable<number> {
		return this.applicationFacade.update$;
	}

	public initVersion() {
		super.addSubscription(this.environmentService.interval$()
			.pipe(
				filter(config => typeof config !== undefined),
				withLatestFrom(this.applicationFacade.version$)
			)
			.subscribe(([config, version]) => {
				if (version) {
					if (config.version !== version) {
						switch (EnvironmentUtils.getVersionUpdate(config, version)) {
							case EnvironmentUpdateEnum.MAJOR:
								this.setVersion(version, EnvironmentUpdateEnum.MAJOR);
								break;
							case EnvironmentUpdateEnum.MINOR:
								this.setVersion(version, EnvironmentUpdateEnum.MINOR);
								break;
							case EnvironmentUpdateEnum.PATH:
								this.setVersion(version, EnvironmentUpdateEnum.PATH);
								break;
						}
					}
				} else {
					this.setVersion(config.version, undefined);
				}
			})
		);
	}

	/** RESIZE section */
	public initResize() {
		this._windowdDestroy();
		this.setMediaQuerySize(this._getMediaQuerySize(window));
		this._listener = (event: any) => this.onResizeListener(event);
		window.addEventListener('resize', this._listener);
	}

	private _windowdDestroy() {
		if (this._listener) {
			// remove event listener
			window.removeEventListener('resize', this._listener);
		}
		this._listener = undefined;
	}

	private _getMediaQuerySize(window: Window): MediaQuerySizeType {
		const width = window.innerWidth;
		return width <= MediaQueryLimit.SMALL ? MEDIA_QUERY_SIZE.SMALL :
			width <= MediaQueryLimit.MEDIUM ? MEDIA_QUERY_SIZE.MEDIUM :
				width <= MediaQueryLimit.LARGE ? MEDIA_QUERY_SIZE.LARGE : MEDIA_QUERY_SIZE.EXTRA_LARGE;
	}

	private onResizeListener(event: UIEvent) {
		let currentDimension;
		this.getMediaQuerySize$()
			.pipe(take(1))
			.subscribe((next) => currentDimension = next)
			.unsubscribe();
		const newDimension = this._getMediaQuerySize(event.target as Window);
		if (currentDimension !== newDimension) {
			this.setMediaQuerySize(newDimension);
		}
	}

	public clearState$(): Observable<boolean> {
		this.applicationFacade.dispatch(ApplicationActions.ClearState());
		return scheduled([true], asyncScheduler);
	}
}
