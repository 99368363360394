<form *ngIf="data" [ngClass]="data?.class ? data.class : ''">
    <button mat-button color="primary" class="close" mat-dialog-close *ngIf="data && !data.closeIcon">
        <mat-icon class="icon-close" color="primary"></mat-icon>
    </button>
    <h1 *ngIf="dialogTitle()" mat-dialog-title>{{dialogTitle()}}</h1>
    <mat-dialog-content>
      <div [innerHTML]="data.content"></div>
      <mat-slide-toggle hideIcon="true" class="margin-top-L" xSmsSimulate *ngIf="data.xSmsSimulate && !isProduction"
                        [(ngModel)]="xSmsSimulate" [ngModelOptions]="{standalone: true}" >
        {{'App.General.Dev_Simulate_Sms' | translate}}
      </mat-slide-toggle>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="data.actions" [attr.align]="dialogAction().align">
        <button mat-button *ngFor="let action of data.actions"
                color="{{action.color ? action.color : dialogAction().color}}"
                [ngClass]="action.extraClass ? action.extraClass : ''"
                [mat-dialog-close]="!action.closeButton ? ( xSmsSimulate ? {xSmsSimulate: xSmsSimulate} : action.title): null"
                [attr.cdkFocusInitial]="action.selected">
            {{ action.title | uppercase }}
        </button>
    </mat-dialog-actions>
</form>
