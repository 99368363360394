<mat-sidenav-container>
    <mat-sidenav #sidenav position="start" disableClose="true" [mode]="sidenavMode" color="primary">
        <ng-container [ngTemplateOutlet]="menu"></ng-container>
    </mat-sidenav>

    <!--    <mat-sidenav position="end">End</mat-sidenav>-->

    <mat-sidenav-content>
        <ng-container [ngTemplateOutlet]="header"></ng-container>
        <ng-content select="[template-loading]"></ng-content>
        <ng-content select="[template-content]"></ng-content>

        <router-outlet><!-- Here goes the magic navigation --></router-outlet>

        <ng-container [ngTemplateOutlet]="footer"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #menu>
    <mat-toolbar class="no-padding" [color]="sidenavMode === 'over' ? 'dark' : 'grey-dark'">
        <gp-angular-logo class="component-title hide-on-close" [ngClass]="{'light' : sidenavMode === 'over'}"></gp-angular-logo>

        <div class="element-spacer"></div>

        <button mat-mini-fab (click)="sidenav.toggle()" color="light" class="hide-on-small-and-down">
            <mat-icon>menu</mat-icon>
        </button>

        <button mat-icon-button (click)="sidenav.toggle()" class="hide-on-med-and-up">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-toolbar>

    <mat-toolbar class="hide-on-med-and-up" color="dark">
        <div class="small">{{ (name$ | async) }}
            <div class="opacity-5">{{ (email$ | async) }}</div>
        </div>
    </mat-toolbar>

    <mat-toolbar class="flex-space-between hide-on-med-and-up" color="dark">
        <a class="small" [routerLink]="PAGE.ADMIN_SETTING.fullpath">{{'Pages.Provider.User.Settings.Title' | translate}}</a>
        <a class="small" (click)="logout()">{{'Pages.Logout.Label' | translate}}</a>
    </mat-toolbar>

    <mat-nav-list class="no-padding margin-bottom" (click)="sidenavMode === 'over' ? sidenav.toggle() : ''" *ngIf="(permission$ | async) as permission">
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_DATA_VIEW')"
           [routerLink]="PAGE.ADMIN_PROVIDER.fullpath">
            <mat-icon matSuffix>assignment</mat-icon>
            <span class="mat-list-text hide-on-close">{{'Pages.Admin.Provider.Label' | translate}}</span>
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('USERS_MANAGEMENT_VIEW')"
           [routerLink]="PAGE.ADMIN_USER.fullpath">
            <mat-icon matSuffix>people_alt</mat-icon>
            <span class="mat-list-text hide-on-close">{{'Pages.Admin.User.Label' | translate}}</span>
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('CMS_VIEW')"
           [routerLink]="PAGE.ADMIN_CMS.fullpath">
            <mat-icon matSuffix>policy</mat-icon>
            <span class="mat-list-text hide-on-close">{{'Pages.Admin.Cms.Label' | translate}}</span>
        </a>
        <a mat-list-item routerLinkActive="active-list-item" *ngIf="permission.includes('PROFILE_MANAGEMENT_VIEW')"
           [routerLink]="PAGE.ADMIN_GENERAL.fullpath">
            <mat-icon matSuffix>ballot</mat-icon>
            <span class="mat-list-text hide-on-close">{{'Pages.Admin.GeneralData.Title' | translate}}</span>
        </a>
    </mat-nav-list>

    <div class="element-spacer"></div>

    <mat-hint *ngIf="!env.production" class="version margin">v {{env.version}}</mat-hint>
</ng-template>

<ng-template #header>
    <mat-toolbar class="template-header" color="grey">
        <button mat-icon-button class="hide-on-med-and-up show-on-small" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>

        <div class="element-spacer"></div>

        <gp-angular-logo class="component-title hide-on-med-and-up"></gp-angular-logo>

        <ng-content header-content select="[template-header]"></ng-content>

        <div class="element-spacer"></div>

        <button class="user hide-on-small-and-down" mat-button [matMenuTriggerFor]="appMenu">
            <mat-icon>account_circle</mat-icon>
            <div class="margin-left-S text-trim">{{ (name$ | async) }}</div>
            <mat-icon iconPositionEnd>keyboard_arrow_down</mat-icon>
        </button>
    </mat-toolbar>
</ng-template>

<ng-template #footer>
    <!--    <mat-toolbar class="template-footer" color="transparent">-->
    <ng-content footer-content select="[template-footer]"></ng-content>
    <!--    </mat-toolbar>-->
</ng-template>

<mat-menu #appMenu="matMenu">
    <button mat-menu-item color="primary" type="button" [routerLink]="PAGE.ADMIN_SETTING.fullpath">
        {{'Pages.Provider.User.Settings.Title' | translate}}
    </button>

    <button mat-menu-item color="primary" (click)="logout()">
        {{'Pages.Logout.Label' | translate}}
    </button>
</mat-menu>
