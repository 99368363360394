import { jwtDecode } from 'jwt-decode';
import { UserEntity } from './+state/user.models';
import { PermissionRole } from '@gp-angular/shared/schema';
import { UserRoleEnumDTO } from '@noventi/gp-platform/users';

export class InternalUtils {
	public static tokenExtract(payload: any): UserEntity {
		const JWT = jwtDecode(payload.access_token);
		/**
		 * Decode params :)
		 * ped: Password Expiration Date
		 * uan: User Action Needed
		 * 			- mfa: Multi Factor Authentication
		 */
		const UAN = JWT['uan'];

		return {
			expires: new Date(JWT['exp'] * 1000).toJSON(),
			ped: Math.floor(JWT['ped'] / (3600 * 24)),
			pids: JWT['pids'],
			username: JWT['user_name'],
			permissions: JWT['authorities'],
			uan: UAN,
			loading: !UAN || UAN === 'mfa'
		};
	}

	public static permissionByRole(role: string): Array<string> {
		let permission;

		switch (role) {
			case UserRoleEnumDTO.INSTITUTIONMANAGER:
				permission = [...PermissionRole.ROLE_PROVIDER_FULL];
				break;
			case UserRoleEnumDTO.OFFICEMANAGER:
				permission = [...PermissionRole.ROLE_PROVIDER_PATCH];
				break;
			case UserRoleEnumDTO.EMPLOYEE:
				permission = [...PermissionRole.ROLE_PROVIDER_DEFAULT];
				break;
			case UserRoleEnumDTO.SUPERADMIN:
			case UserRoleEnumDTO.ADMIN:
				permission = [...PermissionRole.ROLE_ADMIN_FULL];
				break;
			case UserRoleEnumDTO.CUSTOMERCARE:
				permission = [...PermissionRole.ROLE_ADMIN_DEFAULT];
				break;
			default:
				permission = [];
				break;
		}
		return permission;
	}
}
